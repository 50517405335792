<template>
  <form @submit.prevent="saveAction" class="flex flex-wrap">
    <p>Add an action for this enquiry</p>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">
        Action Type
        <span class="text-red-500">*</span>
      </label>
      <div class="relative">
        <select class="bge-input bge-select rounded" v-model="action.type">
          <option value="Follow Up Call">Follow Up Call</option>
          <option value="Meeting">Meeting</option>
          <option value="Site Visit">Site Visit</option>
          <option value="Other">Other</option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">
        Date &amp; Time
        <span class="text-red-500">*</span>
      </label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="action.date"
        format="dd/MM/yyyy"
        placeholder="Select action date"
      ></datepicker>
      <div class="pt-1 flex justify-between">
        <div class="w-1/3 relative">
          <select class="bge-input bge-select rounded" v-model="action.hour">
            <template v-for="option in hourOptions">
              <option :key="option.value" :value="option.value">{{
                option.text
              }}</option>
            </template>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
          >
            <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
        <div class="w-1/3 relative">
          <select class="bge-input bge-select rounded" v-model="action.minute">
            <template v-for="option in minuteOptions">
              <option :key="option.value" :value="option.value">{{
                option.text
              }}</option>
            </template>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
          >
            <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
              <path
                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full py-2">
      <label class="w-full flex items-center">
        <input
          class="h-5 w-5 form-checkbox focus:shadow-none text-primary focus:border-primary mr-4"
          v-model="action.notify"
          type="checkbox"
        />
        <span>Send a reminder to assigned user(s)</span>
      </label>
    </div>
    <hr class="w-full my-2 border-primary" />
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Name</label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="action.name"
        placeholder="Action Name..."
        type="text"
      />
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Description</label>
      <textarea
        v-model="action.description"
        class="w-full bge-input bge-input-spacing rounded"
        placeholder="Action Description..."
        rows="10"
      ></textarea>
    </div>
    <div class="w-full py-2">
      <label class="uppercase font-semibold text-sm">Assign To</label>
      <div class="relative">
        <select class="bge-input bge-select rounded" v-model="action.userId">
          <template v-for="option in action.userOptions">
            <option :key="option.value" :value="option.value">{{
              option.text
            }}</option>
          </template>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="w-full">
      <button
        type="button"
        @click="saveAction"
        class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 text-secondary py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save Action</span>
      </button>
    </div>
  </form>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  name: "AddEditAction",
  components: {
    Datepicker,
  },
  props: {
    action: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      hourOptions: [],
      minuteOptions: [],
    };
  },
  mounted() {
    if (!this.action.event_id) {
      this.action.date = new Date().setDate(new Date().getDate() + 7);
    }

    for (var hr = 0; hr < 24; hr++) {
      this.hourOptions.push({
        value: hr,
        text: `${String(hr).padStart(2, "0")}`,
      });
    }

    for (var min = 0; min < 60; min += 5) {
      this.minuteOptions.push({
        value: min,
        text: `${String(min).padStart(2, "0")}`,
      });
    }
  },
  methods: {
    saveAction: function() {
      this.$emit("complete", this.action);
    },
  },
};
</script>
