<template>
  <div>
    <div class="flex items-center mb-5">
      <h3 class="uppercase text-lg font-semibold text-primary tracking-wide">
        Actions
      </h3>
      <hr class="flex-1 border-primary mx-4" />
      <button
        @click="openCreateAction"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <circle cx="12" cy="12" r="10" />
          <path d="M12 6v6l4 2" />
        </svg>
        <span>Create Action</span>
      </button>
    </div>
    <div>
      <template v-for="action in sortedActions">
        <div class="pb-6 last:pb-0" :key="action.action_id">
          <div
            class="bg-gray-200 border-gray-200 border-l-4 p-4 flex flex-col rounded"
            :class="{ 'border-green-500': action.is_complete }"
          >
            <h4 class="text-xl">
              <span v-if="action.name">{{ action.name }} -</span>
              {{ action.type }}
            </h4>
            <p class="text-gray-700 flex-1">
              {{ $moment(action.date).format("DD/MM/YYYY [at] HH:mm") }}
              ({{ $moment(action.date).fromNow() }})
            </p>
            <div class="flex items-center border-t border-secondary mt-4 pt-4">
              <div class="text-gray-700 flex-1 flex flex-col">
                <p class="inline-flex items-center py-1">
                  <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                    />
                  </svg>
                  <span v-if="!action.description">(No description)</span>
                  {{ action.description }}
                </p>
                <p class="inline-flex items-center py-1">
                  <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                    <circle cx="12" cy="7" r="4" />
                  </svg>
                  <span
                    v-if="action.assigned_to && action.assigned_to.length > 0"
                    >{{ formatAssignedTo(action) }}</span
                  >
                  <span
                    v-if="!action.assigned_to || action.assigned_to.length == 0"
                    >(Unassigned)</span
                  >
                </p>
              </div>
              <div class="inline-flex">
                <button
                  @click="deleteAction(action)"
                  type="button"
                  class="inline-flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                >
                  <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                    />
                  </svg>
                  <span>Delete</span>
                </button>
                <button
                  @click="toggleActionCompleted(action)"
                  v-if="!action.is_complete"
                  type="button"
                  class="ml-4 inline-flex items-center bg-indigo-200 border hover:bg-indigo-300 border-indigo-400 hover:border-indigo-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                >
                  <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
                    <path d="M22 4L12 14.01l-3-3" />
                  </svg>
                  <span>Mark Complete</span>
                </button>
                <button
                  @click="toggleActionCompleted(action)"
                  v-if="action.is_complete"
                  type="button"
                  class="ml-4 inline-flex items-center bg-orange-200 border hover:bg-orange-300 border-orange-400 hover:border-orange-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                >
                  <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <circle cx="12" cy="12" r="10" />
                    <path d="M15 9l-6 6M9 9l6 6" />
                  </svg>
                  <span>Mark Incomplete</span>
                </button>
                <button
                  v-if="!action.is_complete"
                  @click="openEditAction(action)"
                  type="button"
                  class="ml-4 inline-flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                >
                  <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                    />
                  </svg>
                  <span>Edit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <p class="text-center" v-if="actions.length == 0">
        No actions have been added to this enquiry yet
      </p>
    </div>

    <portal to="overlay-outlet">
      <panel
        :showing="addEditActionPanelShowing"
        @close="addEditActionPanelShowing = false"
        :title="editingAction.event_id ? 'Edit Action' : 'Add Action'"
      >
        <add-edit-action :action="editingAction" @complete="closeActionPanel" />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert
        :showingConfirm="confirmAlert.showing"
        :message="confirmAlert.message"
        @complete="confirmAlert.resultFunction"
      />
    </portal>
  </div>
</template>

<script>
import Panel from "@/components/Panel.vue";
import Notification from "@/components/Notification.vue";
import ConfirmAlert from "@/components/ConfirmAlert.vue";
import AddEditAction from "@/components/SalesProspects/Edit/AddEditAction.vue";

export default {
  props: {
    actions: Array,
    enquiryId: String,
  },
  components: {
    Panel,
    AddEditAction,
    Notification,
    ConfirmAlert,
  },
  data() {
    return {
      addEditActionPanelShowing: false,
      editingAction: {},
      availableUsers: [],
      userOptionsMapped: [],
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
    };
  },
  computed: {
    sortedActions: function() {
      return this.$_.orderBy(
        this.actions,
        (action) => {
          return action.date;
        },
        "desc"
      );
    },
    actionIsValid() {
      return this.editingAction.date && this.editingAction.type;
    },
  },
  async mounted() {
    try {
      this.availableUsers = await this.UserService.getUsers();
      this.userOptionsMapped = this.availableUsers.map((usr) => {
        return {
          value: usr.user_id,
          text: usr.name,
        };
      });
    } catch (err) {
      console.error("Error while getting users", err);
    }
  },
  methods: {
    openCreateAction() {
      this.editingAction = {
        date: null,
        type: null,
        hour: 9,
        minute: 0,
        userId: null,
        notify: true,
        userOptions: [
          { value: null, text: "Assign to user (optional)" },
          ...this.userOptionsMapped,
        ],
      };
      this.addEditActionPanelShowing = true;
    },
    closeActionPanel: async function() {
      try {
        let dtBase = this.$moment(this.editingAction.date);
        let actionDate = this.$moment([
          dtBase.year(),
          dtBase.month(),
          dtBase.date(),
        ])
          .add(this.editingAction.hour, "hours")
          .add(this.editingAction.minute, "minutes");

        let actionPostData = {
          type: this.editingAction.type,
          date: actionDate.format(),
          name: this.editingAction.name,
          description: this.editingAction.description,
          notify: this.editingAction.notify,
          assigned_to: [],
        };

        // Assign a user if specified
        let assignedUser = this.availableUsers.find((u) => {
          return u.user_id == this.editingAction.userId;
        });

        if (assignedUser) {
          actionPostData.assigned_to.push(assignedUser);
        }

        // Update or create based on whether we have an event_id
        if (this.editingAction.event_id) {
          await this.SalesProspectService.updateProspectAction(
            this.enquiryId,
            this.editingAction.event_id,
            actionPostData
          );
          let idx = this.$_.findIndex(this.actions, (e) => {
            return e.event_id == this.editingAction.event_id;
          });

          actionPostData.is_complete = false;

          this.$set(this.actions, idx, {
            event_id: this.editingAction.event_id,
            ...actionPostData,
          });
        } else {
          let result = await this.SalesProspectService.createProspectAction(
            this.enquiryId,
            actionPostData
          );
          result.data.is_complete = false;
          this.actions.push(result.data);
        }

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Action Saved",
                  close: onClose,
                },
              },
              "Action details saved"
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (err) {
        console.error(err);
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There was a problem creating the action, please try again later"
            );
          },
          {
            position: "top-right",
          }
        );
      }
      this.addEditActionPanelShowing = false;
      this.editingAction = {};
    },
    openEditAction(action) {
      let userId =
        action.assigned_to && action.assigned_to.length > 0
          ? action.assigned_to[0].user_id
          : null;

      let mDate = this.$moment(action.date);
      let actionHour = mDate.isValid() ? mDate.hour() : 9;
      let actionMinute = mDate.isValid() ? mDate.minute() : 0;

      this.editingAction = {
        ...action,
        hour: actionHour,
        minute: actionMinute,
        userId: userId,
        userOptions: [
          { value: null, text: "Assign to user (optional)" },
          ...this.userOptionsMapped,
        ],
      };

      this.addEditActionPanelShowing = true;
    },
    handleDeleteAction: async function(result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          await this.SalesProspectService.deleteProspectAction(
            this.enquiryId,
            this.confirmAlert.data.event_id
          );
          let idx = this.$_.findIndex(this.actions, (e) => {
            return e.event_id == this.confirmAlert.data.event_id;
          });

          this.$delete(this.actions, idx);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    title: "Success",
                    close: onClose,
                  },
                },
                "Action deleted"
              );
            },
            {
              position: "top-right",
            }
          );
        } catch (err) {
          console.error(err);
          this.$breadstick.notify(
            ({ h, onClose }) => {
              return h(
                Notification,
                {
                  props: {
                    type: "danger",
                    title: "Error",
                    close: onClose,
                  },
                },
                "There was a problem deleting the action"
              );
            },
            {
              position: "top-right",
            }
          );
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteAction: function(action) {
      this.confirmAlert = {
        resultFunction: this.handleDeleteAction,
        message: `Are you sure you wish to delete this action?`,
        showing: true,
        data: action,
      };
    },
    toggleActionCompleted: async function(action) {
      try {
        let result = await this.SalesProspectService.updateProspectAction(
          this.enquiryId,
          action.event_id,
          {
            is_complete: !action.is_complete,
          }
        );

        action.is_complete = !action.is_complete;

        let word = action.is_complete ? "complete" : "incomplete";

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Success",
                  close: onClose,
                },
              },
              `Successfully marked this action as ${word}`
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (error) {
        console.error(error);
        let word = action.is_complete ? "incomplete" : "complete";
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              `There was a problem marking this action as ${word}, please try again later`
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    formatAssignedTo: function(action) {
      let assignedNames = action.assigned_to.map((usr) => {
        return usr.name;
      });
      return assignedNames.join(", ");
    },
  },
};
</script>
